import React from 'react';
import * as Mouths from '../PupilAvatarImages/images/Mouths';
import * as Eyes from '../PupilAvatarImages/images/Eyes';
import * as Skins from '../PupilAvatarImages/images/Skins';

export const PupilAvatarViewer = ({ avatar, styleName }) => {
  const Skin = Skins[`MemoSkin${avatar.skin === 0 ? 1 : avatar.skin}`];
  const Eye = Eyes[`MemoEyes${avatar.eyes === 0 ? 1 : avatar.eyes}`];
  const Mouth = Mouths[`MemoMouth${avatar.mouth === 0 ? 1 : avatar.mouth}`];

  return (
    <svg viewBox="0 0 600 600" className={styleName}>
      <rect
        width="100%"
        height="100%"
        fill={`rgb(${avatar.bgColor.r},${avatar.bgColor.g},${avatar.bgColor.b})`}
      />
      <Skin skinsettings={avatar.skinSettings} />
      <Mouth />
      <Eye eyecolor={avatar.eyeColor} />
    </svg>
  );
};
